import { minWidth } from '@mui/system';
import { navy, tiffany, orange, lightGray, lightBlue, textColor, iconGray, bgGray, closeDrawer, fabBtn, ellipsis, green } from '../../../css/style';

const homeDrawerWidth = '22%';
const mobileHomeDrawerWidth = '25%';

export const MatchesComponentStyle = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerTop: {
    minHeight: '347px',
    flexShrink: 0,
  },
  drawerPaperTop: {
    width: '100%',
    zIndex: 100,
    paddingTop: '180px',
  },
  drawer: {
    width: homeDrawerWidth,
    flexShrink: 0,

    [theme.breakpoints.down('md')]: {
      width: mobileHomeDrawerWidth,
    },
  },
  drawerPaper: {
    width: homeDrawerWidth,
    zIndex: 100,
    overflowY: 'hidden',
    paddingTop: '140px',

    [theme.breakpoints.down('md')]: {
      width: mobileHomeDrawerWidth,
    },
  },
  externDrawerPaper: {
    width: homeDrawerWidth,
    zIndex: 100,
    overflowY: 'hidden',
    paddingTop: '150px',

    [theme.breakpoints.down('md')]: {
      width: mobileHomeDrawerWidth,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    minHeight: '40px',

    [theme.breakpoints.down('md')]: {
      minHeight: '56px',
    },
  },

  leftDrawerCloseBtn: {
    ...closeDrawer,
    marginLeft: homeDrawerWidth,

    [theme.breakpoints.down('md')]: {
      marginLeft: mobileHomeDrawerWidth,
    },
  },

  rightDrawerCloseBtn: {
    ...closeDrawer,
    marginLeft: '-14px',
    marginTop: '10px',
  },

  closeIconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  topDrawerCloseBtn: {
    ...closeDrawer,
    marginTop: '-10px',
    position: 'relative',
  },

  matchMobileContainer: {
    flexGrow: 1,
    padding: '16px 0px',
    width: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  contentShiftDown: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginTop: 100,
  },

  matchContainer: {
    flexGrow: 1,
    padding: '24px 0px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${homeDrawerWidth}`,
    marginRight: `-${homeDrawerWidth}`,

    [theme.breakpoints.down('md')]: {
      marginLeft: `-${mobileHomeDrawerWidth}`,
      marginRight: `-${mobileHomeDrawerWidth}`,
    },
  },
  contentShiftLeft: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  contentShiftRight: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  jobCardContainer:{
    padding: '10px',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 224px)',
  },
  mJobCardContainer:{
    padding: '10px',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 180px)',
  },
  mExternJobCardContainer:{
    padding: '10px',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 230px)',
  },
  drawerFabBtn: {
    ...fabBtn,
    backgroundColor: 'white',
    color: navy,
    borderWidth: '1px 1px 0px 1px',
    borderColor: lightGray,
    zIndex: 200,

    '&:hover': {
      backgroundColor: 'white',
    },
  },

  mapViewFabBtn: {
    ...fabBtn,
    backgroundColor: orange,
    color: 'white',
    borderWidth: '1px',
    borderColor: orange,
    left: 'calc(50% - 61px)',
    bottom: '20px', 
    height: '40px',
    flexDirection: 'row',

    '& > * + *': {
      fontSize: '16px',
      fontWeight: 600,
    },

    '&:hover': {
      backgroundColor: orange,
    },

    // mobile styles
    [theme.breakpoints.down('md')]: {
      bottom: '70px', 
    },
  },
  info: {
    fontFamily: 'Asap',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: navy,

    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },

  changeMonth: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '& > *': {
      color: navy,
    },
  },

  matchTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
  },
  searchContainer: {
    marginTop: 10,
  },
  noMatchesText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    fontStyle: 'italic',
    color: navy,
    marginTop: '50px',
  },
  matchIteminfoIcon: {
    color: 'white',
    marginRight: 5,
  },
  popper: {
    height: 150,
  },
  popperInner: {
    marginTop: 10,
    position: 'relative',
    width: 200,
    height: 170,
    '&:after': {
      content: '""',
      position: 'relative',
      top: -190,
      left: 175,
      borderBottom: '10px solid #4bbdad',
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderTop: '10px solid transparent',
      zIndex: 10,
    },
  },
  formLabel: {
    marginLeft: 0,
  },
  popperHeader: {
    display: 'flex',
    width: '100% ',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#4bbdad',
    color: 'white',
    borderRadius: '4px 4px 0px 0px',
  },
  popperHeaderText: {
    fontSize: 12,
    marginLeft: 8,
    fontWeight: 700,
  },
  popperHeaderClearButton: {
    paddingRight: 0,
    color: 'white',
    textTransform: 'none',
    textDecoration: 'underline',
    fontSize: '12 px',
  },
  displayFilterIcon: {
    right: 0,
    position: 'absolute',
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
  },
  icon: {
    color: orange,
    paddingTop: 2,
    fontSize: '1.7rem',
  },

  filterButton: {
    padding: '1px 12px',
    fontSize: '0.7rem',
    color: 'white',
    fontWeight: 700,
  },
  filterContainer: {
    marginTop: 20,
    marginBottom: 30,
    paddingRight: 40,
    position: 'relative',
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    width: '20%',
    fontFamily: 'Asap',
    '&:hover': {
      cursor: 'default',
    },
  },
  hoverPointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerItemValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.7rem',
    lineHeight: '1.7rem',
    fontWeight: 700,
    color: navy,
  },
  headerItemTitle: {
    fontSize: '0.9rem',
    color: '#777',
  },
  header: {
    padding: '50px 0',
    // mobile styles
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-evenly',
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      justifyContent: 'space-evenly',
    },
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
    },
  },

  jobMatchContainer: {
    // mobile styles
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      justifyContent: 'center',
    },
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    minHeight: 400,
  },
  modalHeaderIcon: {
    fontSize: '20px',
    color: `${green}`,
    marginRight: '4px',
  },
  modalHeader: {
    fontSize: '22px',
    fontWeight: 700,
    color: `${green}`,
  },
  navLink: {
    color: `${tiffany}`,
    marginLeft: '16px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  confirmEmailContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(252, 74, 26, 0.1)',
    padding: '10px',
  },
  warningIcon: {
    width: '15px',
    height: '15px',
    marginRight: '10px',
  },
  calendarFilterContainer: {
    width: '100%',
    maxWidth: 570,
    minHeight: 373,
    display: 'flex',
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    borderRadius: '3px',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      maxWidth: 'auto',
      minHeight: 'auto',
      margin: '10px 0px',
    },
  },
  filterSection: {
    justifyContent: 'space-between',
    marginBottom: '25px',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginBottom: '16px',
    },
  },
  bcRoot: {
    '& > * + *': {
      fontSize: '13px',
      color: lightBlue,
    },
    display: 'flex',
    alignItems: 'center',
    padding: `8px 24px`,
    flexWrap: 'wrap',
  },
  text: {
    fontSize: '13px',
    color: lightBlue,
  },
  linkText: {
    fontSize: '13px',
  },
  chipText: {
    margin: '2px 8px',
    borderRadius: '4px',
    textTransform: 'capitalize',
  },
  drawerContainer: {
    width: '100%',
    padding: '20px',
    overflowY: 'auto',
  },
  drawerHeading: {
    fontSize: '20px !important',
    fontWeight: 700,
    paddingLeft: '10px',

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  mapContainer: {
    overflow: 'visible',
    paddingTop: 10,
    width: '100%',
    height: 'calc(100vh - 130px)',
  },
  mMapContainer: {
    overflow: 'visible',
    paddingTop: 10,
    width: '100%',
    height: 'calc(100vh - 180px)',
  },
  ssIcon: {
    color: '#9e9e9e',
    margin: 3,
  },
  ssItem: {
    minHeight: 36,
    alignItems: 'center',
    display: 'flex',
  },
  searchTitle: {
    textTransform: 'capitalize',
    color: '#404A59',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19.2px',
    ...ellipsis,
    maxWidth: '210px',

    '@media screen and (max-width: 1366px) and (min-width: 1024px)' : {
      maxWidth: '155px',
    },

    [theme.breakpoints.down('md')]: {
      maxWidth: '170px',
    },
  },
  searchAddress: {
    color: textColor,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19.2px',
    ...ellipsis,
  },

// SavedSearchComponentStyle
  container: {
    paddingTop: 20,
  },
  icon: {
    color: '#9e9e9e',
    margin: 3,
  },
  savedSearchGridContainer: {
    height: 'fit-content',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  addButton: {
    backgroundColor: `${orange}`,
    color: '#fff',
    minHeight: 56,
    '&:hover': {
      backgroundColor: `${orange}`,
    },
    '&:active': {
      backgroundColor: `${orange}`,
    },
    '&:focus': {
      backgroundColor: `${orange}`,
    },
  },
  item: {
    '& p': {
      fontSize: '0.8rem',
      whiteSpace: 'normal',
    },
    minHeight: 36,
    alignItems: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  row: {
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  connectModal: {
    fontWeight: '700',
    fontSize: 20,
    width: '100%',
  },
  message: {
    backgroundColor: '#FFFFFF',
    maxWidth: 500,
    width: '80%',
    minWidth: 275,
    fontSize: 20,
    textAlign: 'center',
    padding: 22,
    fontFamily: 'Asap',
    borderRadius: 5,
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
  },
  closeIcon: {
    float: 'right',
    display: 'block',
    marginLeft: 'calc(100% - 50px)',
  },
  textField: {
    width: '80%',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  textFieldInput: {
    textAlign: 'center',
    fontWeight: 400,
    MozAppearance: 'textfield',
  },
  modalButton: {
    backgroundColor: navy,
    width: '80%',
    color: '#FFFFFF',
    marginTop: 8,

    '&:hover': {
      backgroundColor: navy,
    },
  },

  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '116px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '96px',
    },
  },
  externMobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '76px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '71px',
    },
  },
  searchTermContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
  },

  matchSeachBar: {
    width: '372px',
    height: '36px',

    // mobile styles
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: 340,
    },
  },
  snackBarRoot: {
    '& .MuiSnackbar-anchorOriginTopCenter': {
      top: '202px',
    },
  },
  mobileSearchContainer: {
    overflowY: 'auto',
    height: '100%',

    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#233160',
  },
  whiskerIcon: {
    marginLeft: 8,
    width: '65px',
    height: '14px',
  },
  rotationSelectContainer: {
    minWidth: '215px',
    position: 'relative',
    width: '372px',
    

    // mobile styles
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: 340,
    },
  },
  rotationIcon: {
    position: 'absolute',
    top: 6,
    left: 7,
  },
  rotationSelect: {
    width: '100%',
    '& .MuiSelect-root': {
      padding: '9px 32px',
      textAlign: 'center',
    }
  }
});
