import isString from 'lodash/isString';
import { Button, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { SignUpPageStyle } from '../../css/style';
import EmailBar from '../subcomponents/EmailBar';
import SocialBar from '../subcomponents/SocialBar';
import ResetPasswordDialog from './subcomponents/ResetPasswordDialog';
import { LOGIN_ERROR_MESSAGES } from '../constants/Constants';

const FORGOT_PASSWORD = 'CHANGE PASSWORD';
const FORGOT_PASSWORD_DESCRIPTION = 'Please enter your email below. If we have an account with that email we\'ll send you a verification token to reset your password.';
const CHANGE_PASSWORD = 'CHANGE PASSWORD';
const CHANGE_PASSWORD_DESCRIPTION = 'A 5-digit verification code was sent to #EMAIL#. To reset your password, please enter it below and create your new password.';

export class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordDialogOpen: false,
      resetPasswordDialogOpen: false,
      email: '',
      invalidEmail: '',
      invalidPassword: '',
      isExtern: false,
    };
  }

  componentDidMount() {
    const { user, actions } = this.props;

    if (this.props.location?.pathname?.includes('externlogin')) {
      this.setState({ isExtern: true })
    }

    if (user) {
      actions.logoutUser();
    }
  }

  handleCloseDialog = (dialog) => {
    this.setState({
      forgotPasswordDialogOpen: false,
      resetPasswordDialogOpen: false,
      email: '',
    });
  };

  handleOnClickDialog = (type, data) => {
    const { actions } = this.props;

    if (type === 'forgotPassword') {
      actions.sendResetPasswordToken({ email: data.email }, ['users', 'forgot-password'])
        .then((e) => {
          if (!e.error) {
            this.setState({
              forgotPasswordDialogOpen: false,
              resetPasswordDialogOpen: true,
              email: data.email,
            });
          }
        });
    } else {
      actions.resetPassword(data, ['users', 'reset-password'])
        .then((e) => {
          if (!e.error) {
            this.setState({
              resetPasswordDialogOpen: false,
              email: '',
            });
          }
        });
    }
  };

  handleEmailSignInClick = (email, password) => {
    const { actions, history, user } = this.props;
    const { invalidEmail, invalidPassword } = this.state;
    if (email.length === 0) {
      this.setState({ invalidEmail: 'Please enter your email' });
    } else if (password.length === 0) {
      this.setState({ invalidPassword: 'Please enter your password' });
    } else if (!invalidEmail && !invalidPassword) {
      actions.loginUser({ email, password, isTalent: true })
        .then((e) => {
          if (!e.error) {
            let intentURL = sessionStorage.getItem('intent');

            if (intentURL && intentURL === '/externinfo') {
              intentURL = ''
            }

            if (intentURL) {
              sessionStorage.removeItem('intent');
            }

            const redirectUrl = intentURL || (e?.loggedInUser?.isStudent ? '/externhome' : '/matches');
            history.push(redirectUrl);
          } else {
            const message = isString(e.error) ? e.error : isString(e.error.message) ? e.error.message : '';
            switch (message) {
              case 'EMAIL_REQUIRED':
              case 'INVALID_EMAIL':
              case 'ACCESS_DENIED':
              case 'TALENTS_ACCESS_DENIED':
                this.setState({ invalidEmail: LOGIN_ERROR_MESSAGES[message] });
                break;
              case 'PASSWORD_REQUIRED':
              case 'INVALID_PASSWORD':
                this.setState({ invalidPassword: LOGIN_ERROR_MESSAGES[message], invalidEmail: null });
                break;
              default:
                this.setState({ invalidEmail: message });
                break;
            }
          }
        });
    }
  };

  renderForm = () => {
    const { classes } = this.props;
    const { invalidEmail, invalidPassword } = this.state;
    return (
      <Grid
        container
        item
        xs={10}
        lg={12}
        justifyContent="center"
        className={classes.emailInputLoginContainer}
      >
        <span className={classes.loginTitle}>Worker Log In: </span>
        <EmailBar
          emailPlaceHolder="Email Address"
          passwordPlaceHolder="Password"
          buttonText="Sign in"
          onClick={this.handleEmailSignInClick}
          color={1}
          isLoginForm
          invalidLoginEmail={invalidEmail}
          invalidLoginPassword={invalidPassword}
          onEmailChange={() => this.setState({ invalidEmail: null })}
          onPasswordChange={() => this.setState({ invalidPassword: null })}
        />
        <a
          className={classes.link}
          style={{ marginTop: 10 }}
          onClick={e => this.setState({ forgotPasswordDialogOpen: true })}
        >
          Forgot Password
        </a>
      </Grid>
    );
  };

  getDialogDescription = () => {
    const { forgotPasswordDialogOpen, email } = this.state;
    const regex = /#EMAIL#/g;
    return forgotPasswordDialogOpen ? FORGOT_PASSWORD_DESCRIPTION : CHANGE_PASSWORD_DESCRIPTION.replace(regex, email);
  };

  render() {
    const { classes, history } = this.props;
    const { forgotPasswordDialogOpen, resetPasswordDialogOpen } = this.state;

    return (
      <Grid
        container
        align="center"
        justifyContent="center"
        className={classes.signUpGridContainer}
      >
        <Typography className={classes.signUpTitle}>
          WELCOME BACK! SIGN IN TO GET THE PERFECT MATCH!
        </Typography>
        {this.renderForm()}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <div className={classes.memberSignIn}>
          Don’t have an account yet?
          {
            this.state.isExtern ? (
              <Button className={classes.signInButton} onClick={() => history.push('/extern-signup')}>
                Sign Up
              </Button>
            ) : (
              <Button className={classes.signInButton} onClick={() => window.location = 'https://jobs.jobvite.com/pathwaypartners/job/ohyCnfwy'}>
                Sign Up
              </Button>
            )
          }
        </div>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {
          forgotPasswordDialogOpen || resetPasswordDialogOpen
            ? (
              <ResetPasswordDialog
                type={forgotPasswordDialogOpen ? 'forgotPassword' : 'changePassword'}
                open={forgotPasswordDialogOpen || resetPasswordDialogOpen || false}
                title={forgotPasswordDialogOpen ? FORGOT_PASSWORD : CHANGE_PASSWORD}
                description={this.getDialogDescription()}
                handleCloseDialog={dialog => this.handleCloseDialog(dialog)}
                handleOnClick={(type, data) => this.handleOnClickDialog(type, data)}
              />
            ) : null
        }
      </Grid>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  history: PropTypes.shape({
    action: PropTypes.string,
    location: PropTypes.object,
    length: PropTypes.number,
    push: PropTypes.func,
  }).isRequired,
};

export default withStyles(SignUpPageStyle)(withRouter(LoginPage));
