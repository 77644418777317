/* eslint-disable react/jsx-max-props-per-line */
import { Container, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { VET_TYPES, STUDENT_VET_TYPES } from '../../config/enums';
import { OnboardingSearchesSimpleSelectableSquareStyle, TalentTypePageStyle } from '../../css/style';
import BackButton from '../subcomponents/BackButton';
import SimpleSelectableIconComponent from './itemTypes/SimpleSelectableIconComponent';

class TalentType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  componentDidMount = async () => {
    const {
      user,
      actions,
    } = this.props;
    if (user) {
      await actions.getAllResources(user.token, 'talents');
    }
  }

  setTalentType(type, extra) {
    const {
      actions,
      history,
      user,
      location,
    } = this.props;
    const obj = { type };
    if (extra) {
      extra.forEach(item => obj[item.key] = item.value);
    }
    
    Promise.all([
      actions.updateResource('talents', user.token, obj, 'talents', user.talent_id),
      (type === 'vet-tech-or-assistant' || type === 'dvm-doctor-of-veterinary-medicine') && actions.getAllResources(user.token, 'schools')
    ]).then(() => {
      if (location?.state?.from) {
        history.push({
          pathname: "/onboarding",
          state: { from: location?.state?.from }
        })
      } else {
        history.push('/onboarding')
      }

    });
  }

  render() {
    const {
      classes,
      resources,
    } = this.props;
    const { talents } = resources;
    const talent = talents && talents.length ? talents[0] : {};
    let vetTypes = talent.isStudent ? STUDENT_VET_TYPES : VET_TYPES;
    const isExtern = this.props.location?.state?.from === "isExternUser";
    if (isExtern) {
      vetTypes = vetTypes.filter(x => x.isExtern)
    }

    return (
      <Container maxWidth="lg" className={classes.talentTypeContainer}>
        <div
          className={classes.talentTypeGridContainer}
        >
          <Grid item className={classes.talentTypeGridItem}>
            <div className={classes.talentTypeInnerContainer}>
              <Typography
                className={classes.talentTitle}
                color="secondary"
              >
                {'WHAT DESCRIBES YOU THE BEST?'}
              </Typography>
              <Grid
                container
                align="center"
                spacing={2}
                justifyContent="space-evenly"
                className={(talent.isStudent || isExtern) ? classes.narrowSelectableIconContainer : classes.selectableIconContainer}
              >
                {
                  vetTypes.map(type => (
                    <Grid
                      item
                      xs={6} sm={(talent.isStudent || isExtern) ? 6 : 4} lg={(talent.isStudent || isExtern) ? 6 : 2}
                      key={type.id}
                    >
                      <SimpleSelectableIconComponent
                        icon={type.id}
                        title={type.title.toUpperCase()}
                        selected={type.key === talent.type && (!type.extra || type.extra.every(item => talent[item.key] === item.value))}
                        onClick={() => this.setTalentType(type.key, type.extra)}
                        style={OnboardingSearchesSimpleSelectableSquareStyle}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </div>
            <div>
              <BackButton />
            </div>
          </Grid>
        </div>
      </Container>
    );
  }
}

TalentType.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  history: PropTypes.shape({
    action: PropTypes.string,
    location: PropTypes.object,
    length: PropTypes.number,
    push: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    token: PropTypes.string,
    talent_id: PropTypes.number,
  }),
};

TalentType.defaultProps = {
  user: {
    token: null,
    talent_id: null,
  },
};

export default withStyles(TalentTypePageStyle)(withRouter(TalentType));
