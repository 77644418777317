import React from 'react';
import {
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { DesciplineComponentStyle } from '../../../css/desciplineComponentStyle';
import { EXTERN_DISCIPLINE } from '../../../config/enums';
import { isEmpty } from 'lodash';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
class ExternDesciplineComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: [] };
  }

  componentDidMount() {
    const { initState1, initState2 } = this.props;
    const selected = [];
    if (!isEmpty(initState1)) {
      const value = initState1.find(x => x.criteria_id);
      selected.push(value.strValue);
    }
    if (!isEmpty(initState2)) {
      const value = initState2.find(x => x.criteria_id);
      selected.push(value.strValue);
    }
    this.setState({ selected });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected !== this.state.selected) {
      this.handleExternDiscipline(this.state.selected);
    }
  }

  /**
   * Handle extern discipline click
   * ---
   * ### Condition
   * - User can't choose `None` in the first time
   * - If user chose a option, `None` is enabled
   * - User must choose 2 options to continue the process
   * ---
   * @param {string} key
   */
  onExternDisciplineClick(key) {
    const { selected } = this.state;
    if (selected.includes(key)) {
      const index = selected.indexOf(key);
      if (index > -1) {
        if (length === 2 && key !== 'None' && selected.includes('None')) {
          this.setState({ selected: [] });
          this.handleExternDiscipline([]);
        } else {
          const s = selected;
          s.splice(index, 1);
          this.setState({ selected: s });
          this.handleExternDiscipline(s);
        }
      }
    } else {
      const s = selected;
      s.push(key);
      this.setState({ selected: s });
      this.handleExternDiscipline(s);
    }
  }

  handleExternDiscipline(data) {
    let result = [];
    data.forEach((item, index) => {
      result.push({ criteria: `extern-discipline-${index + 1}`, strValue: item });
    });
    this.props.handleExternDisciplineChange(result);
  }

  render() {
    const { classes } = this.props;
    const { selected } = this.state;
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography className={classes.title}>What two disciplines would you like to externship under?</Typography>
        </Grid>
        <Grid
          container
          spacing={1}
          className={classes.gridContainer}
        >
          {
            EXTERN_DISCIPLINE.map((item) => {
              const isDisabled = (selected.length === 0 && item === 'None') || (selected.length === 2 && !selected.includes(item));
              const isSelected = selected.includes(item);
              return (
                <Grid item key={item}>
                  <Paper onClick={e => this.onExternDisciplineClick(item)} className={isDisabled ? classes.disabledSquare : isSelected ? classes.selectedSquare : classes.square}>
                    <Typography gutterBottom align="center" color="inherit" className={classes.squareText}>
                      {item}
                    </Typography>
                  </Paper>
                </Grid>
              );
            })
          }
        </Grid>
      </Grid>
    );
  }
}

ExternDesciplineComponent.propTypes = {
  handleExternDisciplineChange: PropTypes.func.isRequired,
};

export default withStyles(DesciplineComponentStyle)(withRouter(ExternDesciplineComponent));
