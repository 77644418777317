import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  ButtonBase,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DomainIcon from '@mui/icons-material/Domain';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PlaceIcon from '@mui/icons-material/Place';
import EventIcon from '@mui/icons-material/Event';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { PropTypes } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { orange, green, lightBlue, aquaGreen, lightGray, navy } from '../../css/style';
import { JobMatchCardStyle } from '../../css/jobMatchCardStyle';
import { badgeColor, IMG_STORAGE_LINK, CORONADO_BADGE_TITLE } from '../constants/Constants';
import { isPrivateStaff, checkIsBoostedJob, formatPostTitle, getJobRoleText, isReliefOrExternshipType } from '../../utils/Functions';

const fastpassIcon = `${IMG_STORAGE_LINK}green-ticket.png`;

class JobMatchCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  viewMatchDetails = (item) => {
    const { search } = this.props;
    localStorage.setItem('viewDetailsMatchIdStr', item.id);
    this.props.history.push(`/${search ? 'jobpostings' : 'matches'}/${item.jobPosting ? item.jobPosting.id : item.id}`);
  };

  getBadge(title, id) {
    const { classes } = this.props;
    return (
      <Button
        className={classes.badgeFilter}
        key={title}
        style={{
          backgroundColor: badgeColor(id || title),
        }}
        ref={title}
      >
        {title}
      </Button>
    );
  }

  getBadges() {
    const { item } = this.props;
    return item && item.badges && item.badges.length && (
      <div
        style={{
          height: 30,
          marginTop: 8,
          overflow: 'hidden',
          paddingRight: '50px',
          position: 'relative',
        }}
      >
        {item.badges.map(badge => this.getBadge(badge.title, badge.id))}
        {this.moreBadges()}
      </div>
    ) || '';
  }

  moreBadges() {
    const { item } = this.props;
    let lineTops = [];
    const publicRef = this.refs.public;
    const privateRef = this.refs['private-staff'];
    const customRef = this.refs.superhero;
    let firstLineTop = customRef ? customRef.offsetTop : null;
    if (!firstLineTop) {
      firstLineTop = publicRef ? publicRef.offsetTop : null;
    }
    if (!firstLineTop) {
      firstLineTop = privateRef ? privateRef.offsetTop : null;
    }
    if (firstLineTop && publicRef && firstLineTop !== publicRef.offsetTop) {
      lineTops.push(privateRef.offsetTop);
    }
    if (firstLineTop && privateRef && firstLineTop !== privateRef.offsetTop) {
      lineTops.push(privateRef.offsetTop);
    }
    item.badges.map((badge) => {
      let ref = this.refs[badge.title];
      if (ref) {
        if (!firstLineTop) {
          firstLineTop = ref.offsetTop;
        }
        if (ref.offsetTop != firstLineTop) {
          lineTops.push(ref.offsetTop);
        }
      }
    });
    if (lineTops && lineTops.length > 0) {
      return (
        <span
          style={{
            position: 'absolute',
            top: 10,
            right: 0,
            fontSize: '14px',
            fontFamily: 'Asap',
          }}
        >
          +
          {lineTops.length}
          {' '}
          more
        </span>
      );
    } else {
      return null;
    }
  }

  renderShifts() {
    const {
      classes, item, search,
      showSchedule = () => {}, fastpassData,
      isMobile,
    } = this.props;
    const jobPosting = search ? item : item.jobPosting;
    const isFastPass = !isEmpty(item) &&  jobPosting?.typeOfEmployment === 'Relief'
    && fastpassData?.find(fp => fp.practice_id === item?.practice?.id);
    const showShiftCalendar = item?.status === 'connected' && isFastPass && (item?.badges?.length === 1 && item.badges[0].title === CORONADO_BADGE_TITLE);

    if (showShiftCalendar) {
      return (
        <ButtonBase onClick={() => showSchedule(item)} key={`${item.id}_calendar_btn`} style={{ textAlign: 'left' }}>
          {!isMobile && (<EventIcon className={classes.matchItemLinkIcon} />)}
          <Typography  key={`${item.id}_calendar_text`} className={classes.matchItemLink}>View Shift Calendar</Typography>
        </ButtonBase>
      );
    } else if (!isReliefOrExternshipType(jobPosting.typeOfEmployment) || !item.dateList) {
      return  (
        <>
          {!isMobile && (<AccessTimeIcon className={classes.matchIteminfoIcon} />)}
          <Typography component='span' key={`${item.id}_job_type`} className={isMobile ? classes.matchItemInfoText : classes.matchItemText}>{jobPosting?.typeOfEmployment}</Typography>
        </>
      );
    } else {
      return (
        <>
          {!isMobile && (<EventIcon className={classes.matchIteminfoIcon} />)}
          <Typography component='span'  key={`${item.id}_date_list`} className={isMobile ? classes.matchItemInfoText : classes.matchItemText} style={{ fontWeight: 600 }}>{item?.dateList}</Typography>
        </>
      );
    }
  };


  render() {
    const {
      classes, item, confirmConnectToMatch, user, history, search,
      enableDelete, unreadCount, deleteMatch = () => {}, showSchedule = () => {}, open, fastpassData,
      handlePracticeClick = () => {},
      isMobile,
      externHome = false,
    } = this.props;
    const { practice } = item;
    const showNewIcon = item.status === 'new';
    const showConnectButton = !!user && item.status !== 'connected';
    const showSignInButton = !user;
    const showDeleteButton = enableDelete && !!user;
    const enableConnectButton = user && user.isVerified;
    const jobPosting = search ? item : item.jobPosting;
    // const boostDescription = isPrivateStaff(user) ? jobPosting?.staffDescription : jobPosting?.contractDescription;
    // const isBoostedJob = checkIsBoostedJob(boostDescription, jobPosting);
    const isBoostedJob = jobPosting?.isBoostedByDates;
    const isConnected = item.status === 'connected';
    const isArchived = jobPosting && jobPosting.deletedAt && !jobPosting.isDeleted;
    const isDeleted = jobPosting && jobPosting.deletedAt && jobPosting.isDeleted;
    const isgreyedOut = (isConnected && isDeleted) || false;
    const jobRoleText = getJobRoleText(item?.jobPosting?.talentType, item?.jobPostingCriteriaItems);
    const rotationTypes = item.jobPosting && item.jobPosting.rotationTypes && JSON.parse(item.jobPosting.rotationTypes);
    const isFastPass = !isEmpty(item) &&  jobPosting?.typeOfEmployment === 'Relief'
      && fastpassData?.find(fp => fp.practice_id === item?.practice?.id);

    return isMobile ? (
      <Card
        key={`${item.id}_m_card`}
        id={`${item.id}_m_card`}
        className={classes.card}
        style={{
          mixBlendMode: isgreyedOut ? 'luminosity' : '',
          border: isBoostedJob ? `1px solid ${orange}` : isFastPass ? `1px solid ${green}` : '1px solid transparent',
        }}
      >
        <CardHeader
          action={
            showDeleteButton ? (
              <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                deleteMatch()
              }}
              key={`${item.id}_m_close_icon`}
            >
              <CloseIcon style={{ fontSize: '20px' }} />
            </IconButton>
            ) : null
          }
          title={(
            <Grid container style={{ display: 'flex', alignItems: 'center', width: '100%'}} justifyContent='space-between'>
              {isBoostedJob &&
                <Grid item xs={5} className={classes.cardHeaderContainer}>
                  <img
                    src={`${IMG_STORAGE_LINK}dollar.svg`}
                    style={{ width: 20, height: 20, marginRight: '5px' }}
                    alt='boosted' title='boosted'
                  />
                  <Typography key={`${item.id}_m_boosted`} className={classes.fastpassText}>BOOSTED</Typography>
                </Grid>
              }
              {isFastPass &&
              <Grid item xs={5} className={classes.cardHeaderContainer}>
                <img style={{ width: 20, height: 20, marginRight: '5px' }} src={fastpassIcon} alt='fastpass' title='fastpass'/>
                <Typography key={`${item.id}_m_fastpass`} className={classes.fastpassText}>FAST PASS</Typography>
              </Grid>
              }
              {(isArchived || isDeleted) &&
                <Grid item xs={2}>
                  <div className={classes.newIconContainer} style={{ minWidth: '45px' }}>
                    <img
                      src={`${IMG_STORAGE_LINK}warning_icon.svg`}
                      className={classes.newIcon}
                    />
                    <Typography variant="caption" component="p" className={classes.newIconText}>
                      {isArchived ? 'ARCHIVED' : 'DELETED'}
                    </Typography>
                  </div>
                </Grid>
              }
            </Grid>
          )}
          style={{ padding: '16px 16px 8px 16px' }}
        />
        <CardContent  style={{ padding: '0px 16px' }}>
          <Grid
            className={classes.infoGrid}
            direction="row"
            container
            key={`${item.id}_m_container`}
          >
            <Grid item xs={12} key={`${item.id}_m_title_container`} className={classes.matchIteminfoItem}>
              {showNewIcon && (
                <NewReleasesIcon style={{ fontSize: '20px', marginRight: '4px', color: orange }} />
              )}
              {item.status === 'connected' && (
                <EmojiEmotionsIcon  style={{ fontSize: '20px', marginRight: '4px', color: aquaGreen }} />
              )}
              <Typography key={`${item.id}_m_title`} color="secondary" className={classes.infoTypography}>
                {formatPostTitle(item?.jobPosting?.title)}
              </Typography>
            </Grid>
            <Grid item xs={12} key={`${item.id}_m_dates_container`} className={classes.matchIteminfoItem}>
              {this.renderShifts()}
              
            </Grid>
            {item.jobPosting.typeOfEmployment === 'Externship' && rotationTypes && rotationTypes.length > 0 && (
              <Grid item xs={12} className={classes.matchIteminfoItem}>
                <Tooltip title={`Externship Rotation: ${rotationTypes?.join(', ')}`}>
                  <Typography component='span'>
                    <span>{rotationTypes?.join(', ')}</span>
                  </Typography>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={12} key={`${item.id}_m_practice_container`} className={classes.matchIteminfoItem}>
              <ButtonBase onClick={() => handlePracticeClick(item?.practice.id)} key={`${item.id}_m_practice_name_btn`} style={{ textAlign: 'left' }}>
                <Typography  key={`${item.id}_m_practice_name`} className={classes.matchItemLink}>{item?.practice?.name}</Typography>
              </ButtonBase>
            </Grid>
            <Grid item xs={12} key={`${item.id}_m_location_container`}
              className={classes.matchIteminfoItem}
            >
              <Typography
                key={`${item.id}_m_location`}
                className={classes.matchItemInfoText}
                
              >
                {item?.practice?.location}
              </Typography>
            </Grid>
            {this.getBadges()}
          </Grid>
          
        </CardContent>
        
        <CardActions style={{ padding: '16px' }}>
        <Grid container>
          <Grid item xs={6} className={classes.cardHeaderContainer}>
            <div
              key={`${item.id}_ss_icon`}
              style={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: `#${item.savedSearch.color}`,
                marginRight: '4px',
              }}
            />
            <Typography className={classes.searchTitle}>
              {item.savedSearch.title === 'Externship Work Near Me' ? 'Externships Near Me' : item.savedSearch.title}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.cardActions}>
            {showSignInButton
                && (
                  <ButtonBase
                    key={`${item.id}_login_btn`}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={e => history.push('/login')}
                  >
                    <Typography className={classes.link}>Sign in to connect!</Typography>
                  </ButtonBase>
                )}
                {showConnectButton
                  && (
                  <ButtonBase
                    key={`${item.id}_connect_btn`}
                    size="small"
                    disabled={!enableConnectButton}
                    variant="contained"
                    onClick={(e) => {
                      localStorage.setItem('viewDetailsMatchIdStr', item.id);
                      return confirmConnectToMatch(item);
                    }}
                  >
                    <Typography className={classes.link} style={{ color: orange }}>Connect</Typography>
                  </ButtonBase>
                )}
                {showConnectButton
                  && (<>
                    <RemoveIcon style={{ rotate: 'z 90deg', color: lightGray, fontSize: '18px' }} />
                  <ButtonBase
                    key={`${item.id}_details_btn`}
                    size="small"
                    variant="contained"
                    onClick={e => this.viewMatchDetails(item)}
                  >
                    <Typography className={classes.link}>Details</Typography>
                  </ButtonBase>
                  </>
                )}
                { isConnected
                  && (
                  <ButtonBase
                    key={`${item.id}_msg_btn`}
                    size="small"
                    variant="contained"
                    onClick={(e) => {
                      localStorage.setItem('viewDetailsMatchIdStr', item.id);
                      history.push(`/messages/${item.id}`);
                    }}
                  >
                    <Typography className={classes.link}>Message/Request Shifts</Typography>
                  </ButtonBase>
                )}
            </Grid>
          </Grid>
          
        </CardActions>
      </Card>
    )
    : ( // desktop
      <div className={classes.cardContainer}>
        <Card
          key={`${item.id}_card`}
          id={`${item.id}_card`}
          className={classes.card}
          style={{
            mixBlendMode: isgreyedOut ? 'luminosity' : '',
            border: isBoostedJob ? `1px solid ${orange}` : isFastPass ? `1px solid ${green}` : '1px solid transparent',
          }}
        >
          <CardContent style={{ padding: '8px' }}>
            <Grid container>
              {isBoostedJob &&
                <Grid item xs={5} className={classes.cardHeaderContainer}
                >
                  <img
                    src={`${IMG_STORAGE_LINK}dollar.svg`}
                    style={{ marginRight: '5px' }}
                  />
                  <Typography key={`${item.id}_boosted`} className={classes.headerText}>BOOSTED</Typography>
                </Grid>
              }
              <Grid item
                xs={isBoostedJob ? 5 : 10} className={classes.cardHeaderContainer}
                style={{ visibility: isFastPass ? 'visible' : 'hidden' }}
              >
                <img style={{ width: 20, height: 20, marginRight: '5px' }}
                  src={ `${IMG_STORAGE_LINK}green-ticket.png`}
                  alt='fastpass' title='fastpass'                  
                />
                <Typography
                  key={`${item.id}_fastpass`}
                  className={classes.headerText}
                >FAST PASS</Typography>
              </Grid>
              
              <Grid item xs={2} className={classes.iconGridItem} key={`${item.id}_delete_item`}>
                {showDeleteButton
                && (
                  <Tooltip title='delete' aria-label='delete' enterDelay={700} placement='top' key={`${item.id}_tooltip_btn`}>
                    <img
                      src={`${IMG_STORAGE_LINK}delete_icon.svg`}
                      onClick={() => deleteMatch()}
                      className={classes.deleteIcon}
                      key={`${item.id}_delete_btn`}
                    />
                  </Tooltip>
                )
                }
              </Grid>
              <Grid item xs={10}  className={classes.cardHeaderContainer} style={{ height: '58px' }}>
                <Typography className={classes.jobTitle}>
                  {formatPostTitle(jobPosting?.title)}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.iconGridItem}>
                { isConnected && (isArchived || isDeleted)
                  && (
                    <div className={classes.newIconContainer} style={{ minWidth: '45px' }}>
                      <img
                        src={`${IMG_STORAGE_LINK}warning_icon.svg`}
                        className={classes.newIcon}
                      />
                      <Typography variant="caption" component="p" className={classes.newIconText}>
                        {isArchived ? 'ARCHIVED' : 'DELETED'}
                      </Typography>
                    </div>
                  )
                }
                { isConnected && !(isArchived || isDeleted)
                  && (
                    <div className={classes.newIconContainer} style={{ minWidth: '32px' }}>
                      <EmojiEmotionsIcon style={{ fontSize: '20px', color: aquaGreen }} />
                      <Typography component="p" className={classes.connectedIconText} >
                        {item.connectDate && moment(item.connectDate).format('MM.DD.YY')}
                      </Typography>
                    </div>
                  )
                }
                { showNewIcon
                  && (
                    <div className={classes.newIconContainer}>
                      <NewReleasesIcon className={classes.newIcon} />
                      <Typography variant="caption" component="p" className={classes.newIconText}>NEW</Typography>
                    </div>
                  )
                }
              </Grid>
            </Grid>
            <Grid container className={classes.matchItemInfo}>
              <Grid item xs={12} className={classes.matchIteminfoItem}>
                {this.renderShifts()}
              </Grid>
              {item.jobPosting.typeOfEmployment === 'Externship' && rotationTypes && rotationTypes.length > 0 && (
                <Grid item xs={12} className={classes.matchIteminfoItem}>
                  <AddBoxIcon className={classes.matchIteminfoIcon} />
                  <Tooltip title={`Externship Rotation: ${rotationTypes?.join(', ')}`}>
                    <Typography component='span'>
                      <span>{rotationTypes?.join(', ')}</span>
                    </Typography>
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={12} className={classes.matchIteminfoItem}>
              {externHome ? (
                <>
                  <DomainIcon className={classes.matchIteminfoIcon} />
                  <Typography className={classes.matchItemText}>{practice?.name || '-'}</Typography>
                </>
              ) :(
                <ButtonBase onClick={() => handlePracticeClick(practice.id)} key={`${item.id}_practice_name_btn`} style={{ textAlign: 'left' }}>
                  <DomainIcon className={classes.matchItemLinkIcon} key={`${item.id}_practice_name_icon`}/>
                  <Typography  key={`${item.id}_practice_name`} className={classes.matchItemLink}>{practice ? practice.name : '-'}</Typography>
                </ButtonBase>
              )}

              </Grid>
              <Grid item xs={12} className={classes.matchIteminfoItem}>
                <PlaceIcon className={classes.matchIteminfoIcon} />
                <Typography className={classes.matchItemText}>{practice ? practice.location : '-'}</Typography>
              </Grid>
            </Grid>
            {this.getBadges()}
          </CardContent>
          <CardActions style={{ padding: '8px' }}>
            <Grid container>
              <Grid item xs={6} className={classes.cardHeaderContainer}>
                <div
                  key={`${item.id}_ss_icon`}
                  style={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: `#${item.savedSearch.color}`,
                    marginRight: '4px',
                  }}
                />
                <Typography className={classes.searchTitle}>
                  {item.savedSearch.title === 'Externship Work Near Me' ? 'Externships Near Me' : item.savedSearch.title}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.cardActions}>
                {showSignInButton
                && (
                  <Button
                    key={`${item.id}_login_btn`}
                    size="small"
                    className={classes.lightNavyButton}
                    variant="contained"
                    color="primary"
                    onClick={e => history.push('/login')}
                  >
                    Sign in to connect!
                  </Button>
                )}
                {showConnectButton
                  && (
                  <Button
                    key={`${item.id}_connect_btn`}
                    size="small"
                    disabled={!enableConnectButton}
                    className={classes.redButton}
                    style={{ marginRight: '8px' }}
                    variant="contained"
                    onClick={(e) => {
                      localStorage.setItem('viewDetailsMatchIdStr', item.id);
                      return confirmConnectToMatch(item);
                    }}
                  >
                    Connect
                  </Button>
                )}
                {showConnectButton
                  && (
                  <Button
                    key={`${item.id}_details_btn`}
                    size="small"
                    className={classes.lightNavyButton}
                    variant="contained"
                    onClick={e => this.viewMatchDetails(item)}
                  >
                    Details
                  </Button>
                )}
                { isConnected
                  && (
                  <Button
                    key={`${item.id}_msg_btn`}
                    size="small"
                    className={classes.lightNavyButton}
                    variant="contained"
                    onClick={(e) => {
                      localStorage.setItem('viewDetailsMatchIdStr', item.id);
                      history.push(`/messages/${item.id}`);
                    }}
                  >
                    Message/Request Shifts
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
    );
  }
}

JobMatchCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any),
  search: PropTypes.bool,
  confirmConnectToMatch: PropTypes.func,
  refreshStats: PropTypes.func,
  enableDelete: PropTypes.bool,
};

JobMatchCard.defaultProps = {
  user: null,
  actions: null,
  enableDelete: false,
  confirmConnectToMatch: () => {
  },
};

export default withStyles(JobMatchCardStyle)(withRouter(JobMatchCard));
