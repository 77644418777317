import moment from 'moment';
import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  Grid,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ArrowBackIos,
  ArrowForwardIos,
} from '@mui/icons-material';
import WeekHeader from './WeekHeader';
import Month from './Month';

import CalendarButtons from './CalendarButtons';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { defaultUtils as utils } from './dateUtils';
import  DateUtilities, { capitalizeFirstLetter } from './utils';
import { orange, green, navy, gray, orangeLight } from '../../css/style';
import { IMG_STORAGE_LINK } from '../constants/Constants';

const useStyles = makeStyles(theme => ({
  root: {
    flex: '1',
    display: 'flex',
    minHeight: '351px',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  selectorContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  toolbarContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'space-between',
    },
    textAlign: 'center',
    color: '#243060',
    padding: '8px 20px',
    alignItems: 'center',
  },
  calendarContainer: {
    width: '100%',
    minHeight: '60px',
    overflowY: 'auto',
    padding: '0px 16px',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      maxWidth: '350px',
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '320px',
    },

  },
  dateTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    marginTop: '16px',
    marginBottom: '8px',
  },
  dateText: {
    fontFamily: 'Asap',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: navy,

    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  changeMonth: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
    width: '24px',
    height: '24px',
    padding: '8px',
    marginBottom: '-90px',

    '& > *': {
      color: '#243060',
    },

    [theme.breakpoints.down('md')]: {
      height: '55px',
    },
  },
  tagDiv: {
    display: 'flex',
    marginRight: '16px',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  tag: {
    margin: '0 5px 0 10px',
    width: '12px',
    height: '12px',
    borderRadius: '50%',

    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  squareTag: {
    margin: '0 5px 0 10px',
    width: '12px',
    height: '12px',
    borderRadius: '2px',
  },
  tagContainer: {
    fontSize: '10px',
    color: '#404A59',
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      //padding: '10px 0px 0px 0px',
      height: '50px',
    },
  },
  mobileClearAllContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '8px 20px',
    alignItems: 'center',
    backgroundColor: navy,
  },
  button: {
    width: '100%',
    height: '33px',
    letterSpacing: 'normal',
    textAlign: 'center',
    borderRadius: '5px',
    marginRight: '10px',
    textTransform: 'capitalize',
    padding: '5px',

    '& > * + *': {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '19.2px',

      [theme.breakpoints.down('md')]: {
        fontSize: '13px',
        lineHeight: '15.6px',
      },
    },

    [theme.breakpoints.down('md')]: {
      height: '32px',
    },
  },
  submitBtn: {
    background: '#364D96',
    border: '1px solid #364D96',
    fontFamily: 'Asap',
    fontWeight: 600,
    fontSize: 13,
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: 4,
    textTransform: 'capitalize',
    marginLeft: 10,
    minWidth: 70,
    '&:hover': {
      background: '#233160',
    },
    '&:disabled': {
      background: '#DADFF1',
      border: '1px solid #DADFF1',
      color: '#ffffff',
    }
  },
  resetButton: {
    background: '#ffffff',
    border: '1px solid #233160',
    fontFamily: 'Asap',
    fontWeight: 600,
    fontSize: 13,
    textAlign: 'center',
    color: '#233160',
    borderRadius: 4,
    textTransform: 'capitalize',
    '&:disabled': {
      border: '1px solid #CACACE',
    }
  },
  iconButtonText: {
    color: '#fff',
    fontSize: '13px',
    fontWeight: 500,
  },
  error: {
    color: 'red',
    paddingTop: 8,
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'center',
  },
  boostText: {
    fontFamily: 'Asap',
    fontWeight: 700,
    fontSize: 14,
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      fontWeight: 400,
    },
  },
}));

const CalendarDetailsPage = ({
  isMobile = false,
  availableDates,
  notAvailableDates,
  requestedDates,
  confirmedDates,
  pendingDates,
  readOnly,
  onSelect,
  onClearAll,
  onOk,
  onChat,
  disabledConnect = false,
  isFastpass = false,
  submitButtonText,
  cancelButtonText,
  chatButtonText,
  warningMsg,
  withoutDateDisplay,
  withoutTips,
  withoutActions,
  calendarData,
  initialDate,
}) => {
  const calendar = useRef(null);
  const classes = useStyles();

  const [displayDate, setDisplayDate] = useState(() => {
    return utils.getFirstDayOfMonth(new Date());
  });
  const [displayDate2, setDisplayDate2] = useState(utils.addMonths(displayDate, 1));
  const [displayDate3, setDisplayDate3] = useState(utils.addMonths(displayDate, 2));

  const dateTimeFormatted = moment(displayDate).format('MMMM YYYY');
  const dateTimeFormatted2 = moment(displayDate2).format('MMMM YYYY');
  const dateTimeFormatted3 = moment(displayDate3).format('MMMM YYYY');

  const [boostedRows, setBoostedRows] = useState([]);
  const [boostDetails, setBoostDetails] = useState({});

  useEffect(() => {
    if (initialDate) {
      setDisplayDate(new Date(initialDate));
      setDisplayDate2(utils.addMonths(new Date(initialDate), 1));
      setDisplayDate3(utils.addMonths(new Date(initialDate), 2));
    }
  }, [initialDate])

  useEffect(() => {
    if (!isEmpty(calendarData)) {
      const boostedRows = calendarData.filter(row =>  row.status === 'available' && row.is_boosted);
      setBoostedRows(boostedRows);
    }
  }, [calendarData]);

  useEffect(() => {
    if (!readOnly && requestedDates?.length > 0 && boostedRows?.length > 0) {
      const boostedRow = boostedRows.find(r => DateUtilities.isSameDay(DateUtilities.midDayDate(r.date), requestedDates[requestedDates.length - 1]));
      if(boostedRow) {
        setBoostDetails({ amount: boostedRow?.boost_amount, date: boostedRow?.date });
      }
    } else {
      setBoostDetails({});
    }
  }, [requestedDates, readOnly]);

  const handleMonthChange = (months) => {
    setDisplayDate(displayDate => utils.addMonths(displayDate, months));
    setDisplayDate2(displayDate => utils.addMonths(displayDate, months));
    setDisplayDate3(displayDate => utils.addMonths(displayDate, months));
  };

  const maxDate = utils.addYears(new Date(), 100);
  const minDate = utils.addYears(new Date(), -100);

  const toolbarInteractions = {
    prevMonth: utils.monthDiff(displayDate, minDate) > 0,
    nextMonth: utils.monthDiff(displayDate, maxDate) < 0,
  };

  const handleTouchTapPrevMonth = (e) => {
    const months = isMobile ? -1 : -3;
    e.preventDefault();
    handleMonthChange(months);
  };

  const handleTouchTapNextMonth = (e) => {
    const months = isMobile ? 1 : 3;
    e.preventDefault();
    handleMonthChange(months);
  };

  const renderTags = (col) => {
    return (
      <Grid container item xs={col} className={classes.tagContainer}>
        <div className={classes.tagDiv}>
          <div className={classes.tag} style={{ backgroundColor: green }} />
          <span>Available</span>
        </div>
        <div className={classes.tagDiv}>
          <div className={classes.tag} style={{ border: `1px solid ${orange}` }} />
          <span>Taken</span>
        </div>
        <div className={classes.tagDiv}>
          <div className={classes.tag} style={{ backgroundColor: isFastpass ? orangeLight : '#243060' }} />
          <span>{isFastpass ? 'Take Shift' : 'Requested'}</span>
        </div>
        { !isEmpty(pendingDates) &&
        (<div className={classes.tagDiv}>
          <div className={classes.tag} style={{ backgroundColor: gray }} />
          <span>Pending</span>
        </div>)}
        { !isEmpty(confirmedDates) &&
        ( <div className={classes.tagDiv}>
          <div className={classes.squareTag} style={{ backgroundColor: navy }} />
          <span>Confirmed</span>
        </div>)}
        { boostedRows?.length > 0 && (
          <div className={classes.tagDiv}>
            <img
              src={`${IMG_STORAGE_LINK}dollar.svg`}
              style={{ height: '12px' }}
            />
            <span>Boosted</span>
          </div>
          )}
      </Grid>
    );
  }

  const renderCalendar = (displayDate, dateTimeFormatted) => (<>
    {isMobile && (
    <Grid item xs={1} className={classes.changeMonth}>
      <IconButton disabled={!toolbarInteractions.prevMonth} onClick={handleTouchTapPrevMonth}>
        <ArrowBackIos style={{ fontSize: '14px'}} />
      </IconButton>
    </Grid>
    )}
    <Grid item xs={isMobile ? 10 : 4} className={classes.calendarContainer}>
      <Grid item  className={classes.dateTextContainer} key={`monthName_${dateTimeFormatted}`}>
        <Typography variant='subtitle1' className={classes.dateText}>{capitalizeFirstLetter(dateTimeFormatted)}</Typography>
      </Grid>
      <Grid item  key={`weekHeader_${dateTimeFormatted}`}>
        <WeekHeader largeSize/>
      </Grid>
      <Grid item  style={{ marginBottom: '16px' }} key={`month_${dateTimeFormatted}`}>
        <Month
          displayDate={displayDate}
          key={displayDate.toDateString()}
          selectedDates={availableDates}
          requestedDates={requestedDates}
          notAvailableDates={notAvailableDates}
          pendingDates={pendingDates}
          confirmedDates={confirmedDates || []}
          minDate={minDate}
          maxDate={maxDate}
          onSelect={onSelect}
          readOnly={readOnly}
          ref={calendar}
          isFastpass={isFastpass}
          calendarData={calendarData}
        />
      </Grid>
    </Grid>
    {isMobile && (
    <Grid item xs={1} className={classes.changeMonth} style={{ justifyContent: 'flex-end' }}>
      <IconButton disabled={!toolbarInteractions.nextMonth} onClick={handleTouchTapNextMonth}>
        <ArrowForwardIos style={{ fontSize: '14px'}} />
      </IconButton>
    </Grid>
    )}
  </>)

  return (
    <div className={classes.root}>
      <div className={classes.selectorContainer}>
        { !isMobile ? (
        <Grid container className={classes.toolbarContainer}>
          <Grid item xs={1} className={classes.changeMonth}>
            <IconButton disabled={!toolbarInteractions.prevMonth} onClick={handleTouchTapPrevMonth}>
              <ArrowBackIos style={{ fontSize: '14px'}} />
            </IconButton>
          </Grid>
          {renderTags(10)}
          <Grid item xs={1} className={classes.changeMonth} style={{ justifyContent: 'flex-end' }}>
            <IconButton disabled={!toolbarInteractions.nextMonth} onClick={handleTouchTapNextMonth}>
              <ArrowForwardIos style={{ fontSize: '14px'}} />
            </IconButton>
          </Grid>
        </Grid>
        )
        : !readOnly
          ? (
          <Grid container item 
            className={classes.mobileClearAllContainer}
            style={ disabledConnect || isEmpty(requestedDates)
                  ? { color: '#b2b2b0'} : {}}
          >
            <Grid item xs={4}>
              <IconButton
                onClick={onClearAll}
                disabled={disabledConnect}
                style={{padding : 0}}
              >
                <Typography
                  className={classes.iconButtonText}
                  style={isEmpty(requestedDates)
                    ? { color: '#b2b2b0' } : {}}
                >
                  Clear All
                </Typography>
              </IconButton>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              { Boolean(chatButtonText)
                && (
                  <IconButton
                    onClick={onChat}
                    disabled={disabledConnect}
                    style={{padding : 0}}
                  >
                    <Typography
                      className={classes.iconButtonText}
                      style={disabledConnect || (isFastpass && isEmpty(requestedDates))
                        ? { color: '#b2b2b0' } : {}}
                    >
                      {chatButtonText}
                    </Typography>
                  </IconButton>
              )}
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                onClick={onOk}
                disabled={disabledConnect || (isFastpass && isEmpty(requestedDates))}
                style={{padding : 0}}
              >
                <Typography
                  className={classes.iconButtonText}
                  style={disabledConnect || (isFastpass && isEmpty(requestedDates))
                    ? { color: '#b2b2b0' } : {}}
                >
                  {submitButtonText}
                </Typography>
              </IconButton>
            </Grid>
          </Grid>
        )
          : null}
        <Grid container xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          {(isMobile ?
            [{
            displayDate: displayDate,
            dateTimeFormatted: dateTimeFormatted
            }] : [{
            displayDate: displayDate,
            dateTimeFormatted: dateTimeFormatted
          }, {
            displayDate: displayDate2,
            dateTimeFormatted: dateTimeFormatted2
          }, {
            displayDate: displayDate3,
            dateTimeFormatted: dateTimeFormatted3
          }]).map((item) => renderCalendar(item.displayDate, item.dateTimeFormatted))}
        </Grid>
        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
          {renderTags(12)}
        </Grid>

        {!withoutActions && (
          <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 40, marginBottom: 10 }}>
            {!readOnly && !warningMsg && isEmpty(boostDetails) && <Grid item className={classes.helper}>{`Click available dates to ${isFastpass ? 'claim' : 'request'} them`}</Grid>}
            {!isEmpty(boostDetails) && (
              <Grid item className={classes.boostText} style={{ color: orange }}>{`${boostDetails.date && moment(boostDetails.date).format('MM/DD')} - $${boostDetails.amount}`}</Grid>
            )}
            {warningMsg && (<Grid className={classes.error}>
              <ErrorOutlineIcon fontSize='small' />
              <span style={{marginLeft: '5px'}}>{warningMsg}</span>
            </Grid>)}
          </Grid>
        )}
        {!isMobile && !withoutActions && !readOnly && (
          <Grid container item style={{ display: 'flex', justifyContent: 'center', margin: '16px 0px' }}>
            <Grid item style={{ marginRight: '16px'}}>
              <Button
                onClick={onClearAll}
                className={classes.resetButton}
                disabled={isEmpty(requestedDates)}
                variant='outlined'
              >
                Clear All
              </Button>
            </Grid>
            {
              Boolean(chatButtonText)
              && (
              <Grid item style={{ marginRight: '16px'}}>
                <Button
                  onClick={onChat}
                  className={classes.submitBtn}
                  disabled={disabledConnect}
                >
                  {chatButtonText}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                onClick={onOk}
                className={classes.submitBtn}
                disabled={disabledConnect || isEmpty(requestedDates)}
              >
                {submitButtonText}
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default withRouter(CalendarDetailsPage);
